import React from 'react';

class ThankYou extends React.Component {
  render() {
    return (
      <div className="newsletter-widget__thankyou" dangerouslySetInnerHTML={{ __html: this.props.texts['thank_you'] }} />
    );
  }
}

export default ThankYou;
