import React from 'react';
import Email from './partials/Email';
import Name from './partials/Name';
import ThankYou from './partials/ThankYou';
import { fetch } from 'whatwg-fetch';

const STAGES = {
  'EMAIL': 'email',
  'NAME': 'name',
  'THANKYOU': 'thank-you',
};

class App extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        interests: []
      },
      loaded: false,
      config: props.config || {},
      stage: STAGES.EMAIL,
    };
  }

  componentDidMount () {
    if (this.props.configEndpoint) {
      fetch(this.props.configEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then((response) => response.json()).then((json) => {
        var config = Object.assign({}, json);
        Object.assign(config, this.state.config);
        Object.keys(json.texts).forEach((key) => {
          var txt = document.createElement("textarea");
          txt.innerHTML = config.texts[key] ? config.texts[key] : json.texts[key];
          config.texts[key] = txt.value;
        });

        var user = this.state.user;
        user.interests = config.interests ? config.interests : [];

        this.setState({
          loaded: true,
          config: config,
          user: user
        });
      }).catch((ex) => {
        console.log('parsing failed', ex);
      });
    } else {
      var config = this.state.config;

      Object.keys(config.texts).forEach((key) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = config.texts[key];
        config.texts[key] = txt.value;
      });

      var user = this.state.user;
      user.interests = config.interests ? config.interests : [];

      this.setState({
        loaded: true,
        config: config,
        user: user
      });
    }
  }

  render() {
    return (
      this.state.loaded ? <div className="newsletter-widget" onClick={this.fieldFocus}>
        {
          this.state.stage === STAGES.EMAIL ? <Email
            endpoint={this.state.config.endpointUrlEmail}
            user={this.state.user}
            texts={this.state.config.texts}
            setAppState={(state) => this.setState({ user: Object.assign(this.state.user, state) })}
            completeCallback={(json) => this.setState({ stage: json.data.require_name && json.data.require_name ? STAGES.NAME : STAGES.THANKYOU })}
          /> : null
        }
        {
          this.state.stage === STAGES.NAME ? <Name
            endpoint={this.state.config.endpointUrlName}
            user={this.state.user}
            texts={this.state.config.texts}
            setAppState={(state) => this.setState({ user: Object.assign(this.state.user, state) })}
            completeCallback={() => this.setState({ stage: STAGES.THANKYOU })}
          /> : null
        }
        {
          this.state.stage === STAGES.THANKYOU ? <ThankYou texts={this.state.config.texts} /> : null
        }
      </div> : null
    );
  }
}

export default App;
