import React from 'react';
import { fetch } from 'whatwg-fetch';

class Email extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      processing: false,
      error: null,
      instance: 1
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    let instances = document.querySelectorAll('.newsletter-widget');
    if (instances.length) {
      this.setState({
        instance: instances.length
      });
    }
  }

  onClick (e) {
    if (!e.target.previousSibling.previousSibling.checkValidity()) {
      e.target.previousSibling.previousSibling.reportValidity();
      return;
    }

    this.setState({
      processing: true,
      error: null
    });

    fetch(this.props.endpoint + '?' +
      new URLSearchParams({'data': JSON.stringify(this.props.user)}), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => response.json()).then((json) => {
      if (!json.success) {
        this.setState({
          processing: false,
          error: this.props.texts[json.error],
        });
      } else {
        this.props.completeCallback(json);
        this.setState({ processing: false });
      }
    }).catch((ex) => {
      this.setState({ processing: false });
      console.log('parsing failed', ex);
    });
  }

  render () {
    if (this.state.processing) {
      return (
        <div className="newsletter-widget__processing" dangerouslySetInnerHTML={{ __html: this.props.texts['request_processing'] }} />
      );
    }

    let newsletterWidgetId = `NewsletterEmailAddress${this.state.instance}`;

    return (
        <div>
          <div className="newsletter-widget__email global-newsletter field text">
            <input className="newsletter__email global-newsletter__email"
                   type="email"
                   id={newsletterWidgetId}
                   value={this.props.user.email}
                   required="required"
                   onChange={(e) => this.props.setAppState({ email: e.target.value })}
            />
            <label className="global-newsletter__label" htmlFor={newsletterWidgetId}>Enter your email address</label>
            <button className="newsletter__submit global-newsletter__subscribe"
                    onClick={this.onClick}
                    type="submit" dangerouslySetInnerHTML={{ __html: this.props.texts['signup_btn_text'] }}/>
          </div>
          {
            this.state.error ? <div
                className="newsletter-widget__error" style={{marginTop: 15}} dangerouslySetInnerHTML={{ __html: this.state.error }}/> : null
          }
        </div>
    );
  }
}

export default Email;
