import React from 'react';
import { fetch } from 'whatwg-fetch';

class Name extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      processing: false,
      error: null
    }

    this.onClick = this.onClick.bind(this);
  }

  onClick (e) {
    if (!e.target.previousSibling.checkValidity() || !e.target.previousSibling.previousSibling.checkValidity()) {
      e.target.previousSibling.reportValidity();
      e.target.previousSibling.previousSibling.reportValidity();
      return;
    }

    this.setState({
      processing: true,
      error: null
    });

    fetch(this.props.endpoint + '?' +
      new URLSearchParams({'data': JSON.stringify(this.props.user)}), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => response.json()).then((json) => {
      if (!json.success) {
        this.setState({
          processing: false,
          error: this.props.texts[json.error],
        });
      } else {
        this.setState({ processing: false });
        this.props.completeCallback(json);
      }
    }).catch((ex) => {
      console.log('parsing failed', ex);
    });
  }

  render () {
    if (this.state.processing) {
      return (
        <div className="newsletter-widget__processing" dangerouslySetInnerHTML={{ __html: this.props.texts['request_processing'] }} />
      );
    }

    return (
        <div>
          <div className="newsletter-widget__name">
            <input className="newsletter__first-name"
                   type="text"
                   placeholder="Enter your first name"
                   value={this.props.user.firstName}
                   required="required"
                   onChange={(e) => this.props.setAppState({ firstName: e.target.value })}
            />
            <input className="newsletter__last-name"
                   type="text"
                   placeholder="Enter your last name"
                   value={this.props.user.lastName}
                   required="required"
                   onChange={(e) => this.props.setAppState({ lastName: e.target.value })}
            />
            <button className="newsletter__submit stage-2 btn btn--small btn--black"
                    onClick={this.onClick}
                    type="submit" dangerouslySetInnerHTML={{ __html: this.props.texts['continue_btn_text'] }}/>
          </div>
          {
            this.state.error ? <div className="newsletter-widget__error" style={{marginTop: 15}} dangerouslySetInnerHTML={{ __html: this.state.error }}/> : null
          }
        </div>
    );
  }
}

export default Name;
