import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/**
 * @param {HTMLElement} container
 */
function render (container) {
  if (container.dataset.configNwEndpoint || container.dataset.configNw) {
    ReactDOM.render(
      <React.StrictMode>
        <App
          configEndpoint={container.dataset.configNwEndpoint}
          config={container.dataset.configNw ? JSON.parse(decodeURI(container.dataset.configNw)) : {}}
        />
      </React.StrictMode>,
      container
    );
  }
}

document.querySelectorAll('[data-config-nw-endpoint], [data-config-nw]').forEach((container) => render(container));

window.blocksOfficeRenderNewsletterWidget = render;
